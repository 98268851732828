import React from "react"
import { graphql, PageProps } from "gatsby"
import { LandingPageLayout } from "components/landing-page-layout"
import { SEO } from "components/seo/seo"
import { PpcHero } from "components/hero/PPCHero/PpcHero"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { TabbedHeroBanner } from "components/hero/TabbedHeroBanner"
import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper"

interface LandingPageProps extends PageProps {
  pageContext: {
    slug: string
    linkedPagesUrlPathCodex: Record<string, string>
  }
  data: any
}

const LandingPage: React.FC<LandingPageProps> = ({
  location,
  data,
  pageContext,
}) => {
  const { pathname } = location
  const { slug, linkedPagesUrlPathCodex } = pageContext
  const [page] = data.allKontentItemPage.nodes
  const {
    banner,
    rich_content,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    seo_metadata__rich_data_image,

    global_footer,
    custom_tracking_number,
    isLandingPage = true,
  } = page.elements

  return (
    <LandingPageLayout
      location={slug}
      pathName={pathname}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      customTrackingNumber={custom_tracking_number.value}
      isLandingPage={isLandingPage}
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        canonical={seo_metadata__canonical_link?.value}
        noIndex={true}
        image={seo_metadata__rich_data_image}
      />

      {banner.value[0].elements.hasOwnProperty("hero_tabs") ? (
        <TabbedHeroBanner banner={banner.value[0].elements} />
      ) : (
        <PpcHero banner={banner.value[0].elements} />
      )}
      <BlocksWrapper>
        {rich_content?.modular_content?.length > 0 && (
          <ContentBlocks content={rich_content.modular_content} />
        )}
      </BlocksWrapper>
      {global_footer && global_footer?.value.length > 0 && (
        <GlobalFooter
          content={global_footer}
          customTrackingNumber={custom_tracking_number.value}
          isLandingPage={isLandingPage}
        />
      )}
    </LandingPageLayout>
  )
}

export const getPage = graphql`
  query ($slug: String!) {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          noindex_this_page {
            value {
              codename
            }
          }
          banner {
            value {
              ...HeroBannerFragment
              ...TabbedHeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
          custom_tracking_number {
            value
          }
        }
      }
    }
  }
`
export default LandingPage
