import React, { useEffect, useRef, useState } from "react"
import { PPCBanner } from "components/hero/HeroBanner-types"
import "../hero-common.module.scss"
import "../hero.module.scss"
import "./ppc-hero.module.scss"

import { FormBuilder } from "components/forms/FormBuilder"
import { HeroContent } from "../HeroContent"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { KontentItemForm } from "components/forms/formBuilder-types"
import { HighlightsGroup } from "components/highlights/highlights-types"

interface HeroProps {
  banner: PPCBanner
}

export const PpcHero = ({ banner }: HeroProps) => {
  const {
    background_image,
    buttons,
    components,
    heading,
    secondary_image,
    sub_heading,
    tagline,
  } = banner

  const { imageBannerColorOverlay } = useColorAreaSettings()
  const prefix: string = "fr-ppc-form"
  const PpcFormRef = useRef<HTMLDivElement>(null)
  const [formHeight, setFormHeight] = useState<number>(0)
  const { size } = useWindowDimensions()

  const form = (
    components?.modular_content.filter(
      component => component?.system?.type === "form"
    )[0] as KontentItemForm
  )?.elements

  const highlights = (
    components?.modular_content?.filter(
      component => component?.system?.type === "highlights_group"
    )[0] as HighlightsGroup
  )?.elements?.highlights?.modular_content

  const bannerColorOverride = imageBannerColorOverlay
    ? ` ${imageBannerColorOverlay}--backgroundColor`
    : ""

  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "hero-background-image",
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 768,
          },
        },
      })
    : {}

  const formStyles = {
    '--form-height': formHeight
  };

  const heroStyles = Object.assign(formStyles, backgroundImageCSSVariable);

  const contentClassName =
    secondary_image && secondary_image?.value?.length > 0
      ? "fr-hero__banner-content-with-image"
      : "fr-hero__banner-content"

  const overrideBackground = imageBannerColorOverlay
    ? ` ${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  useEffect(() => {
    if (!PpcFormRef.current) return

    if (size >= 1366) {
      setFormHeight(0)
      return
    }

    const ppcFormHeight = PpcFormRef.current.clientHeight

    setFormHeight(ppcFormHeight)
  }, [size])

  return (
    <section
      className={ `fr-hero fr-ppc-hero ${bannerColorOverride}${overrideBackground}` }
      style={ heroStyles }
    >
      <div className="fr-ppc-hero__wrapper fr-container fr-container--large">
        <div className={`${contentClassName}`}>
          <HeroContent
            baseClassName={contentClassName}
            buttons={buttons?.value}
            heading={heading?.value}
            highlights={highlights}
            secondaryImage={secondary_image?.value[0]}
            subHeading={sub_heading}
            tagline={tagline}
          />
        </div>
        <div className={prefix}>
          <div className={`${prefix}__wrapper`} ref={PpcFormRef}>
            <div className={`${prefix}__heading-wrapper`}>
              <h2>Get A Free Quote Today!</h2>
            </div>
            {form && (
              <FormBuilder
                isLandingPage
                ctaText={form.submit_button_text?.value}
                customId={form.custom_id?.value}
                formGridStyle="form-grid-2--full-width"
                fields={form.fields}
                submissionMessageBody={form?.submission_message_body?.value}
                submissionMessageHeading={
                  form?.submission_message_heading?.value
                }
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
