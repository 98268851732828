import React from "react"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { AnchorNumber } from "components/main-header/AnchorNumber"
import { Button } from "components/button"
import "./tracking-button.module.scss"

interface TrackingButtonProps {
  isLandingPage?: boolean
  customTrackingNumber: string
}

export const TrackingButton = ({customTrackingNumber, isLandingPage}: TrackingButtonProps) => {
  const {
    customTrackingNumberCtaButtonText,
    trackingNumbers,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const { handleOpenModal } = useTrackingNumberModal()

  const onTrackingClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleOpenModal();
  }

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today!"

  return (
    <>
      { trackingNumbers.length > 0 &&
        trackingNumbers.map(({ trackingNumber, phoneNumber, id }, i) => {
          if (usePhoneNumberNotTrackingNumber && !phoneNumber) return <></>
          if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return <></>
          if (i > 0) return

          return (
            <Button
              extraClassNames="tracking__phone-button"
              key={ i }
              onClick={ trackingNumbers.length > 1 && !isLandingPage || isLandingPage && customTrackingNumber?.length === 0
                ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onTrackingClick(e)
                : () => null
              }
              variant="solid-secondary"
            >
              <AnchorNumber
                classNameModifier="-hidden"
                label={ trackingNumberCTAText }
                key={ id }
                phoneNumber={ phoneNumber }
                customTrackingNumber ={ customTrackingNumber }
                isLandingPage ={ isLandingPage }
                trackingNumber={ trackingNumber }
              />
            </Button>
          )
        }) }
    </>
  )
}
